import React, { useEffect, useState } from "react";
import logoProjet from "../../images/logo-project.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import { confirmResetPassword } from "../../redux/actions/userActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ConfirmPasswordScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uidb64, token, timestamp } = useParams();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

  const passwordConfirmReset = useSelector(
    (state) => state.confirmResetPassword
  );
  const {
    loadingConfirmResetPassword,
    errorConfirmResetPassword,
    successConfirmResetPassword,
  } = passwordConfirmReset;

  useEffect(() => {
    if (successConfirmResetPassword) {
      setNewPassword("");
      setNewPasswordError("");

      setConfirmNewPassword("");
      setConfirmNewPasswordError("");

      navigate("/");
    }
  }, [successConfirmResetPassword]);

  return (
    <div className="min-h-screen w-full bg-[#0388A6] bg-opacity-10 flex flex-col items-center justify-center px-3 ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <a href="/">
        <img src={logoProjet} className="size-24 m-1" />
      </a>
      <div className="my-5  bg-white shadow-4 rounded-md px-3 py-8 md:w-1/2 w-full flex flex-col">
        <div className="text-black text-center  text-2xl font-semibold">
          Reset Password
        </div>
        <div className="text-[#929396] text-center my-2 text-sm">
          Please enter your new password and confirm it to complete your
          password reset request.
        </div>

        {errorConfirmResetPassword && (
          <div className="my-2">
            <Alert type="error" message={errorConfirmResetPassword} />
          </div>
        )}

        <div className="flex md:flex-row flex-col my-3 mx-5">
          <div className=" w-full  md:pr-1 my-1">
            <div className="text-[#303030]  text-sm  mb-1">
              New Password <strong className="text-danger">*</strong>
            </div>
            <div>
              <input
                className={` outline-none border ${
                  newPasswordError ? "border-danger" : "border-[#666666]"
                } px-3 py-2 w-full rounded-full text-sm`}
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(v) => setNewPassword(v.target.value)}
              />
              <div className=" text-[8px] text-danger">
                {newPasswordError ? newPasswordError : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col my-3 mx-5">
          <div className=" w-full  md:pr-1 my-1">
            <div className="text-[#303030]  text-sm  mb-1">
              Confirm Password <strong className="text-danger">*</strong>
            </div>
            <div>
              <input
                className={` outline-none border ${
                  confirmNewPasswordError ? "border-danger" : "border-[#666666]"
                } px-3 py-2 w-full rounded-full text-sm`}
                type="password"
                placeholder="Confirm Password"
                value={confirmNewPassword}
                onChange={(v) => setConfirmNewPassword(v.target.value)}
              />
              <div className=" text-[8px] text-danger">
                {confirmNewPasswordError ? confirmNewPasswordError : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 w-full mx-auto text-center">
          <button
            disabled={loadingConfirmResetPassword}
            className="text-center md:w-1/2 w-full px-5 py-2 rounded-full bg-[#0388A6] text-white mx-auto"
            onClick={() => {
              if (newPassword === "") {
                setNewPasswordError("This field is required");
              } else if (newPassword !== confirmNewPassword) {
                setConfirmNewPasswordError("Please confirm your new password.");
              } else {
                dispatch(
                  confirmResetPassword({
                    password: newPassword,
                    uidb64: uidb64,
                    token: token,
                    timestamp: timestamp,
                  })
                );
              }
            }}
          >
            {loadingConfirmResetPassword ? "Loading..." : "Reset"}
          </button>
        </div>
      </div>
      <div className="text-[#878787] text-center text-sm my-3">
        <span>Copyright © 2024 Atlas Assistance | </span>
        <span className="font-semibold"> Privacy Policy</span>
      </div>
    </div>
  );
}

export default ConfirmPasswordScreen;
