import React, { useEffect, useState } from "react";
import logoProjet from "../../images/logo-project.jpeg";
import iconMessageSend from "../../images/icon/icon-message-send.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SendResetPasswordScreen() {
  return (
    <div className="min-h-screen w-full bg-[#0388A6] bg-opacity-10 flex flex-col items-center justify-center px-3 ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <a href="/">
        <img src={logoProjet} className="size-24 m-1" />
      </a>
      <div className="my-5  bg-white shadow-4 rounded-md px-3 py-8 md:w-1/2 w-full flex flex-col text-center">
        <div className="text-black text-center  text-2xl font-semibold">
          The request has been sent
        </div>
        <img src={iconMessageSend} className="size-24 my-5 mx-auto " />
        <div className="text-[#929396] text-center my-2 text-sm">
          Password reset link has been sent to your email
        </div>
        <div className="text-center my-2 text-sm">
          Please follow the instructions in the email to rest your password
        </div>
      </div>
      <div className="text-[#878787] text-center text-sm my-3">
        <span>Copyright © 2024 Atlas Assistance | </span>
        <span className="font-semibold"> Privacy Policy</span>
      </div>
    </div>
  );
}

export default SendResetPasswordScreen;
