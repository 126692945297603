import axios from "../../axios";
import {
  CASE_LIST_REQUEST,
  CASE_LIST_SUCCESS,
  CASE_LIST_FAIL,
  //
  CASE_LIST_MAP_REQUEST,
  CASE_LIST_MAP_SUCCESS,
  CASE_LIST_MAP_FAIL,
  //
  CASE_ADD_REQUEST,
  CASE_ADD_SUCCESS,
  CASE_ADD_FAIL,
  //
  CASE_DETAIL_REQUEST,
  CASE_DETAIL_SUCCESS,
  CASE_DETAIL_FAIL,
  //
  CASE_UPDATE_REQUEST,
  CASE_UPDATE_SUCCESS,
  CASE_UPDATE_FAIL,
  //
  CASE_DELETE_REQUEST,
  CASE_DELETE_SUCCESS,
  CASE_DELETE_FAIL,
  //
  CASE_COORDINATOR_LIST_REQUEST,
  CASE_COORDINATOR_LIST_SUCCESS,
  CASE_COORDINATOR_LIST_FAIL,
  //
  COMMENT_CASE_LIST_REQUEST,
  COMMENT_CASE_LIST_SUCCESS,
  COMMENT_CASE_LIST_FAIL,
  //
  COMMENT_CASE_ADD_REQUEST,
  COMMENT_CASE_ADD_SUCCESS,
  COMMENT_CASE_ADD_FAIL,
  //
  CASE_ASSIGNED_UPDATE_REQUEST,
  CASE_ASSIGNED_UPDATE_SUCCESS,
  CASE_ASSIGNED_UPDATE_FAIL,
  //
  CASE_INSURANCE_LIST_REQUEST,
  CASE_INSURANCE_LIST_SUCCESS,
  CASE_INSURANCE_LIST_FAIL,
  //
  CASE_PROVIDER_LIST_REQUEST,
  CASE_PROVIDER_LIST_SUCCESS,
  CASE_PROVIDER_LIST_FAIL,
  //
  CASE_PROFILE_LIST_REQUEST,
  CASE_PROFILE_LIST_SUCCESS,
  CASE_PROFILE_LIST_FAIL,
  //
  CASE_DUPLICATE_REQUEST,
  CASE_DUPLICATE_SUCCESS,
  CASE_DUPLICATE_FAIL,
  //
} from "../constants/caseConstants";

// duplicate case
export const duplicateCase = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_DUPLICATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/cases/duplicate/${id}/`, {}, config);

    dispatch({
      type: CASE_DUPLICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CASE_DUPLICATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cases by logged
export const casesListLogged =
  (page, filter = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_PROFILE_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/current-logged/?page=${page}&status=${filter}`,
        config
      );

      dispatch({
        type: CASE_PROFILE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_PROFILE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cases by provider
export const casesListProvider =
  (page, filter = "", provider) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_PROVIDER_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/provider/${provider}/?page=${page}&status=${filter}`,
        config
      );

      dispatch({
        type: CASE_PROVIDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_PROVIDER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cases by insurance
export const casesListInsurance =
  (page, filter = "", insurance) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_INSURANCE_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/insurance/${insurance}/?page=${page}&status=${filter}`,
        config
      );

      dispatch({
        type: CASE_INSURANCE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_INSURANCE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const updateAssignedCase =
  (id, dataCase) => async (dispatch, getState) => {
    console.log("start");

    try {
      dispatch({
        type: CASE_ASSIGNED_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/cases/assigned-to/${id}/`,
        dataCase,
        config
      );

      dispatch({
        type: CASE_ASSIGNED_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);

      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_ASSIGNED_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// case add
export const addNewCommentCase =
  (commentCase, caseId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMMENT_CASE_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/comments/add/${caseId}/`,
        commentCase,
        config
      );

      dispatch({
        type: COMMENT_CASE_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: COMMENT_CASE_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list comment case
export const getListCommentCase =
  (page, caseId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMMENT_CASE_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/comments/case/${caseId}/?page=${page}`,
        config
      );

      dispatch({
        type: COMMENT_CASE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: COMMENT_CASE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cases
export const casesListCoordinator =
  (page, filter = "", coordinator) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_COORDINATOR_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/coordinator/${coordinator}/?page=${page}&status=${filter}`,
        config
      );

      dispatch({
        type: CASE_COORDINATOR_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_COORDINATOR_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const updateCase = (id, caseItem) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/cases/update/${id}/`, caseItem, config);

    dispatch({
      type: CASE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CASE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete case
export const deleteCase = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/cases/delete/${id}/`, config);

    dispatch({
      type: CASE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CASE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// case add
export const addNewCase = (caseItem) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/cases/add/`, caseItem, config);

    dispatch({
      type: CASE_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CASE_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// detail case
export const detailCase = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CASE_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/cases/detail/${id}/`, config);

    dispatch({
      type: CASE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: CASE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// get list cases
export const casesListMap =
  (
    page,
    status = "",
    caseId = "",
    patient = "",
    statusCase = "",
    insurance = "",
    provider = "",
    coordinator = "",
    type = "",
    ciaId = "",
    filterpaid = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_LIST_MAP_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/?page=${page}&status=${status}&patient=${patient}&statuscase=${statusCase}&id=${caseId}&insurance=${insurance}&provider=${provider}&coordinator=${coordinator}&type=${type}&ciaid=${ciaId}&filterpaid=${filterpaid}`,
        config
      );

      dispatch({
        type: CASE_LIST_MAP_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_LIST_MAP_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cases
export const casesList =
  (
    page,
    status = "",
    caseId = "",
    patient = "",
    statusCase = "",
    insurance = "",
    provider = "",
    coordinator = "",
    type = "",
    ciaId = "",
    filterpaid = ""
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CASE_LIST_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/cases/?page=${page}&status=${status}&patient=${patient}&statuscase=${statusCase}&id=${caseId}&insurance=${insurance}&provider=${provider}&coordinator=${coordinator}&type=${type}&ciaid=${ciaId}&filterpaid=${filterpaid}`,
        config
      );

      dispatch({
        type: CASE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: CASE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };
