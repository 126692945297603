import { toast } from "react-toastify";
import {
  CASE_LIST_REQUEST,
  CASE_LIST_SUCCESS,
  CASE_LIST_FAIL,
  //
  CASE_LIST_MAP_REQUEST,
  CASE_LIST_MAP_SUCCESS,
  CASE_LIST_MAP_FAIL,
  //
  CASE_ADD_REQUEST,
  CASE_ADD_SUCCESS,
  CASE_ADD_FAIL,
  //
  CASE_DETAIL_REQUEST,
  CASE_DETAIL_SUCCESS,
  CASE_DETAIL_FAIL,
  //
  CASE_UPDATE_REQUEST,
  CASE_UPDATE_SUCCESS,
  CASE_UPDATE_FAIL,
  //
  CASE_DELETE_REQUEST,
  CASE_DELETE_SUCCESS,
  CASE_DELETE_FAIL,
  //
  CASE_COORDINATOR_LIST_REQUEST,
  CASE_COORDINATOR_LIST_SUCCESS,
  CASE_COORDINATOR_LIST_FAIL,
  //
  COMMENT_CASE_LIST_REQUEST,
  COMMENT_CASE_LIST_SUCCESS,
  COMMENT_CASE_LIST_FAIL,
  //
  COMMENT_CASE_ADD_REQUEST,
  COMMENT_CASE_ADD_SUCCESS,
  COMMENT_CASE_ADD_FAIL,
  //
  CASE_ASSIGNED_UPDATE_REQUEST,
  CASE_ASSIGNED_UPDATE_SUCCESS,
  CASE_ASSIGNED_UPDATE_FAIL,
  //
  CASE_INSURANCE_LIST_REQUEST,
  CASE_INSURANCE_LIST_SUCCESS,
  CASE_INSURANCE_LIST_FAIL,
  //
  CASE_PROVIDER_LIST_REQUEST,
  CASE_PROVIDER_LIST_SUCCESS,
  CASE_PROVIDER_LIST_FAIL,
  //
  CASE_PROFILE_LIST_REQUEST,
  CASE_PROFILE_LIST_SUCCESS,
  CASE_PROFILE_LIST_FAIL,
  //
  CASE_DUPLICATE_REQUEST,
  CASE_DUPLICATE_SUCCESS,
  CASE_DUPLICATE_FAIL,
  //
} from "../constants/caseConstants";

export const duplicateCaseReducer = (state = { caseDuplicate: {} }, action) => {
  switch (action.type) {
    case CASE_DUPLICATE_REQUEST:
      return { loadingCaseDuplicate: true };
    case CASE_DUPLICATE_SUCCESS:
      toast.success("This Case has been duplicated successfully.");
      return {
        loadingCaseDuplicate: false,
        successCaseDuplicate: true,
        caseDuplicate: action.payload.new_case,
      };
    case CASE_DUPLICATE_FAIL:
      toast.error(action.payload);
      return {
        loadingCaseDuplicate: false,
        successCaseDuplicate: false,
        errorCaseDuplicate: action.payload,
      };
    case "RESET_DUPLICATE_CASE":
      return {
        loadingCaseDuplicate: false,
        successCaseDuplicate: false,
      };

    default:
      return state;
  }
};

export const caseListLoggedReducer = (state = { casesLogged: [] }, action) => {
  switch (action.type) {
    case CASE_PROFILE_LIST_REQUEST:
      return { loadingCasesLogged: true, casesLogged: [] };
    case CASE_PROFILE_LIST_SUCCESS:
      return {
        loadingCasesLogged: false,
        casesLogged: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_PROFILE_LIST_FAIL:
      return {
        loadingCasesLogged: false,
        errorCasesLogged: action.payload,
      };
    default:
      return state;
  }
};

export const caseListProviderReducer = (
  state = { casesProvider: [] },
  action
) => {
  switch (action.type) {
    case CASE_PROVIDER_LIST_REQUEST:
      return { loadingCasesProvider: true, casesProvider: [] };
    case CASE_PROVIDER_LIST_SUCCESS:
      return {
        loadingCasesProvider: false,
        casesProvider: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_PROVIDER_LIST_FAIL:
      return {
        loadingCasesProvider: false,
        errorCasesProvider: action.payload,
      };
    default:
      return state;
  }
};

export const caseListInsuranceReducer = (
  state = { casesInsurance: [] },
  action
) => {
  switch (action.type) {
    case CASE_INSURANCE_LIST_REQUEST:
      return { loadingCasesInsurance: true, casesInsurance: [] };
    case CASE_INSURANCE_LIST_SUCCESS:
      return {
        loadingCasesInsurance: false,
        casesInsurance: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_INSURANCE_LIST_FAIL:
      return {
        loadingCasesInsurance: false,
        errorCasesInsurance: action.payload,
      };
    default:
      return state;
  }
};

export const updateCaseAssignedReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_ASSIGNED_UPDATE_REQUEST:
      return { loadingCaseAssignedUpdate: true };
    case CASE_ASSIGNED_UPDATE_SUCCESS:
      toast.success("This Case has been updated successfully.");
      return {
        loadingCaseAssignedUpdate: false,
        successCaseAssignedUpdate: true,
      };
    case CASE_ASSIGNED_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingCaseAssignedUpdate: false,
        successCaseAssignedUpdate: false,
        errorCaseAssignedUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const createNewCommentCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_CASE_ADD_REQUEST:
      return { loadingCommentCaseAdd: true };
    case COMMENT_CASE_ADD_SUCCESS:
      toast.success("This Comment has been added successfully");
      return {
        loadingCommentCaseAdd: false,
        successCommentCaseAdd: true,
      };
    case COMMENT_CASE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingCommentCaseAdd: false,
        successCommentCaseAdd: false,
        errorCommentCaseAdd: action.payload,
      };
    default:
      return state;
  }
};

export const commentCaseListReducer = (state = { comments: [] }, action) => {
  switch (action.type) {
    case COMMENT_CASE_LIST_REQUEST:
      return { loadingCommentCase: true, comments: [] };
    case COMMENT_CASE_LIST_SUCCESS:
      return {
        loadingCommentCase: false,
        comments: action.payload.comments,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case COMMENT_CASE_LIST_FAIL:
      return { loadingCommentCase: false, errorCommentCase: action.payload };
    default:
      return state;
  }
};

export const caseListCoordinatorReducer = (
  state = { casesCoordinator: [] },
  action
) => {
  switch (action.type) {
    case CASE_COORDINATOR_LIST_REQUEST:
      return { loadingCasesCoordinator: true, casesCoordinator: [] };
    case CASE_COORDINATOR_LIST_SUCCESS:
      return {
        loadingCasesCoordinator: false,
        casesCoordinator: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_COORDINATOR_LIST_FAIL:
      return {
        loadingCasesCoordinator: false,
        errorCasesCoordinator: action.payload,
      };
    default:
      return state;
  }
};

export const updateCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_UPDATE_REQUEST:
      return { loadingCaseUpdate: true };
    case CASE_UPDATE_SUCCESS:
      toast.success("This Case has been updated successfully.");
      return {
        loadingCaseUpdate: false,
        successCaseUpdate: true,
      };
    case CASE_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingCaseUpdate: false,
        successCaseUpdate: false,
        errorCaseUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const deleteCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_DELETE_REQUEST:
      return { loadingCaseDelete: true };
    case CASE_DELETE_SUCCESS:
      toast.success("This Case has been successfully deleted.");
      return {
        loadingCaseDelete: false,
        successCaseDelete: true,
      };
    case CASE_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingCaseDelete: false,
        successCaseDelete: false,
        errorCaseDelete: action.payload,
      };
    default:
      return state;
  }
};

export const createNewCaseReducer = (state = {}, action) => {
  switch (action.type) {
    case CASE_ADD_REQUEST:
      return { loadingCaseAdd: true };
    case CASE_ADD_SUCCESS:
      toast.success("This Case has been added successfully");
      return {
        loadingCaseAdd: false,
        successCaseAdd: true,
      };
    case CASE_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingCaseAdd: false,
        successCaseAdd: false,
        errorCaseAdd: action.payload,
      };
    default:
      return state;
  }
};

export const detailCaseReducer = (state = { caseInfo: {} }, action) => {
  switch (action.type) {
    case CASE_DETAIL_REQUEST:
      return { loadingCaseInfo: true };
    case CASE_DETAIL_SUCCESS:
      return {
        loadingCaseInfo: false,
        successCaseInfo: true,
        caseInfo: action.payload,
      };
    case CASE_DETAIL_FAIL:
      return {
        loadingCaseInfo: false,
        successCaseInfo: false,
        errorCaseInfo: action.payload,
      };
    default:
      return state;
  }
};

export const caseListMapReducer = (state = { casesMap: [] }, action) => {
  switch (action.type) {
    case CASE_LIST_MAP_REQUEST:
      return { loadingCasesMap: true, casesMap: [] };
    case CASE_LIST_MAP_SUCCESS:
      return {
        loadingCasesMap: false,
        casesMap: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_LIST_MAP_FAIL:
      return { loadingCasesMap: false, errorCasesMap: action.payload };
    default:
      return state;
  }
};

export const caseListReducer = (state = { cases: [] }, action) => {
  switch (action.type) {
    case CASE_LIST_REQUEST:
      return { loadingCases: true, cases: [] };
    case CASE_LIST_SUCCESS:
      return {
        loadingCases: false,
        cases: action.payload.cases,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case CASE_LIST_FAIL:
      return { loadingCases: false, errorCases: action.payload };
    default:
      return state;
  }
};
