import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  confirmResetPasswordReducer,
  coordinatorsListReducer,
  createCoordinatorReducer,
  createNewUserReducer,
  deleteUserReducer,
  detailCoordinatorReducer,
  getProfileUserReducer,
  historyListCoordinatorReducer,
  historyListLoggedReducer,
  logoutSavedUserReducer,
  resetPasswordReducer,
  updateCoordinatorReducer,
  updateLastLoginUserReducer,
  updatePasswordUserReducer,
  updateProfileUserReducer,
  userLoginReducer,
  usersListReducer,
} from "./reducers/userReducers";
import {
  clientListReducer,
  createNewClientReducer,
  deleteClientReducer,
  detailClientReducer,
  updateClientReducer,
} from "./reducers/clientReducers";

import {
  caseListCoordinatorReducer,
  caseListInsuranceReducer,
  caseListLoggedReducer,
  caseListMapReducer,
  caseListProviderReducer,
  caseListReducer,
  commentCaseListReducer,
  createNewCaseReducer,
  createNewCommentCaseReducer,
  deleteCaseReducer,
  detailCaseReducer,
  duplicateCaseReducer,
  updateCaseAssignedReducer,
  updateCaseReducer,
} from "./reducers/caseReducers";
import {
  addNewProviderReducer,
  deleteProviderReducer,
  detailProviderReducer,
  providerListReducer,
  updateProviderReducer,
} from "./reducers/providerReducers";
import {
  addNewInsuranceReducer,
  deleteInsuranceReducer,
  detailInsuranceReducer,
  insuranceListReducer,
  updateInsuranceReducer,
} from "./reducers/insurancereducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,

  // cases
  caseList: caseListReducer,
  caseListMap: caseListMapReducer,
  detailCase: detailCaseReducer,
  createNewCase: createNewCaseReducer,
  deleteCase: deleteCaseReducer,
  updateCase: updateCaseReducer,
  caseListCoordinator: caseListCoordinatorReducer,
  updateCaseAssigned: updateCaseAssignedReducer,
  caseListInsurance: caseListInsuranceReducer,
  caseListProvider: caseListProviderReducer,
  caseListLogged: caseListLoggedReducer,
  duplicateCase: duplicateCaseReducer,
  // providers
  providerList: providerListReducer,
  detailProvider: detailProviderReducer,
  addNewProvider: addNewProviderReducer,
  deleteProvider: deleteProviderReducer,
  updateProvider: updateProviderReducer,
  //
  clientList: clientListReducer,
  createNewClient: createNewClientReducer,
  detailClient: detailClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  //
  insuranceList: insuranceListReducer,
  addNewInsurance: addNewInsuranceReducer,
  deleteInsurance: deleteInsuranceReducer,
  detailInsurance: detailInsuranceReducer,
  updateInsurance: updateInsuranceReducer,

  //
  usersList: usersListReducer,
  createNewUser: createNewUserReducer,
  getProfileUser: getProfileUserReducer,
  updateProfileUser: updateProfileUserReducer,
  deleteUser: deleteUserReducer,
  updatePasswordUser: updatePasswordUserReducer,
  updateLastLoginUser: updateLastLoginUserReducer,
  historyListLogged: historyListLoggedReducer,
  historyListCoordinator: historyListCoordinatorReducer,
  //
  coordinatorsList: coordinatorsListReducer,
  createCoordinator: createCoordinatorReducer,
  detailCoordinator: detailCoordinatorReducer,
  updateCoordinator: updateCoordinatorReducer,
  //
  commentCaseList: commentCaseListReducer,
  createNewCommentCase: createNewCommentCaseReducer,
  //
  logoutSavedUser: logoutSavedUserReducer,
  resetPassword: resetPasswordReducer,
  confirmResetPassword: confirmResetPasswordReducer,

  //
});

const userInfoFromStorage = localStorage.getItem("userInfoUnimedCare")
  ? JSON.parse(localStorage.getItem("userInfoUnimedCare"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
