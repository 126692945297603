import React, { useEffect, useState } from "react";
import logoProjet from "../../images/logo-project.jpeg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../constants";
import { sendResetPassword } from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPasswordScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();

  const passwordReset = useSelector((state) => state.resetPassword);
  const { loadingResetPassword, errorResetPassword, successResetPassword } =
    passwordReset;

  useEffect(() => {
    if (successResetPassword) {
      setEmail("");
      setEmailError("");

      navigate("/send-reset-password");
    }
  }, [successResetPassword]);

  //
  return (
    <div className="min-h-screen w-full bg-[#0388A6] bg-opacity-10 flex flex-col items-center justify-center px-3 ">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <a href="/">
        <img src={logoProjet} className="size-24 m-1" />
      </a>
      <div className="my-5  bg-white shadow-4 rounded-md px-3 py-8 md:w-1/2 w-full flex flex-col">
        <div className="text-black text-center  text-2xl font-semibold">
          Reset Password
        </div>
        <div className="text-[#929396] text-center my-2 text-sm">
          Please enter your email address to request a password reset.
        </div>

        {errorResetPassword && (
          <div className="my-2">
            <Alert type="error" message={errorResetPassword} />
          </div>
        )}

        <div className="flex md:flex-row flex-col my-3 mx-5">
          <div className=" w-full  md:pr-1 my-1">
            <div className="text-[#303030]  text-sm  mb-1">
              Email <strong className="text-danger">*</strong>
            </div>
            <div>
              <input
                className={` outline-none border ${
                  emailError ? "border-danger" : "border-[#666666]"
                } px-3 py-2 w-full rounded-full text-sm`}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(v) => setEmail(v.target.value)}
              />
              <div className=" text-[8px] text-danger">
                {emailError ? emailError : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 w-full mx-auto text-center">
          <button
            disabled={loadingResetPassword}
            className="text-center md:w-1/2 w-full px-5 py-2 rounded-full bg-[#0388A6] text-white mx-auto"
            onClick={() => {
              if (email === "") {
                setEmailError("This field is required");
              } else if (!validateEmail(email)) {
                setEmailError("Your Email is Invalid");
              } else {
                dispatch(sendResetPassword(email));
              }
            }}
          >
            {loadingResetPassword ? "Loading..." : "Reset"}
          </button>
        </div>
      </div>
      <div className="text-[#878787] text-center text-sm my-3">
        <span>Copyright © 2024 Atlas Assistance | </span>
        <span className="font-semibold"> Privacy Policy</span>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;
